<template>
  <v-row class="d-flex justify-center mt-0 mx-md-2 mx-n1 mb-0">
    <!-- Titulo de Formulario -->
    <v-col cols="12" class="d-flex justify-end align-center pt-0 px-1">
      <div v-if="esNuevaFC == 0">Factura de Compra N° {{ idFactura }}</div>
      <div v-else>Nueva Factura de Compra</div>
      <v-spacer></v-spacer>
      <v-expand-x-transition>
        <v-switch
          v-model="editar"
          label="Editar"
          class="d-flex align-center py-0 my-0"
          hide-details
          dense
          v-if="esNuevaFC == 0 && pagada == 0"
        ></v-switch>
      </v-expand-x-transition>
    </v-col>
    <!-- Contenido -->
    <v-col cols="12">
      <v-card
        flat
        width="auto"
      >
        <v-card-text>
          <!-- Proveedor, Empresa y Sucursal -->
          <v-row class="pt-4">
            <v-col cols="12" sm="2" md="2" class="py-0">
              Empresa
              <v-autocomplete
                v-model="selectedEmpresa"
                item-text="nombre_corto"
                :items="empresas"
                hide-details
                outlined
                dense
                return-object
                :disabled="esNuevaFC == 0"
                @change="getProveedores()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" :sm="multiCuentaProv.length > 0 ? 4 : 5" :md="multiCuentaProv.length > 0 ? 4 : 5" class="py-0">
              Proveedor
              <v-row class="pt-3">
                <v-tooltip color="primary" left v-if="Object.keys(selectedProveedor).length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                      class="mr-2"
                      style="cursor: pointer"
                      small
                    >
                      fas fa-info-circle
                    </v-icon>
                  </template>
                  <div>
                    Cuit: <strong style="margin-left: 17px">{{selectedProveedor.proveedor_cuit}}</strong>
                  </div>
                </v-tooltip>
                <v-autocomplete
                  v-model="selectedProveedor"
                  item-text="proveedor_nombre"
                  :items="proveedores"
                  hide-details
                  outlined
                  dense
                  return-object
                  :disabled="esNuevaFC == 0"
                  @change="verifyMultiCuenta()"
                ></v-autocomplete>
              </v-row>
            </v-col>
            <v-col cols="12" :sm="multiCuentaProv.length > 0 ? 2 : 5" :md="multiCuentaProv.length > 0 ? 2 : 5" class="py-0">
              Sucursal
              <v-autocomplete
                v-model="selectedSucursal"
                item-text="nombre"
                :items="sucursales"
                hide-details
                outlined
                dense
                return-object
                :disabled="editar == false && esNuevaFC == 0"
                clearable
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0" v-if="multiCuentaProv.length > 0">
              Proveedor Bejerman
              <v-autocomplete
                v-model="selectedMultiProv"
                item-text="proveedor_nombre"
                :items="multiCuentaProv"
                hide-details
                outlined
                dense
                return-object
                :disabled="esNuevaFC == 0"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- Fecha, FechaContable, Tipo Factura, Tipo Comprobante -->
          <v-row class="pt-3">
            <v-col cols="12" sm="2" md="2" class="py-0">
              Fecha
              <FechaPicker
                v-model="datosFactura.fecha"
                :disabled="editar == false && esNuevaFC == 0"
              />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="py-0">
              Fecha Contable
              <FechaPicker
                v-model="datosFactura.fecha_contable"
                :disabled="editar == false && esNuevaFC == 0"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0">
              Tipo Comprobante
              <v-autocomplete
                v-model="selectedTipoFactura"
                item-text="tipo_nombre"
                :items="tiposFactura"
                hide-details
                outlined
                dense
                return-object
                :disabled="editar == false && esNuevaFC == 0"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="py-0">
              Emision
              <v-text-field
                v-model="datosFactura.emision"
                outlined
                dense
                type="number"
                :readonly="editar == false && esNuevaFC == 0"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="py-0">
              Numero
              <v-text-field
                v-model="datosFactura.numero"
                outlined
                dense
                type="number"
                :readonly="editar == false && esNuevaFC == 0"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Emision, Numero, Imp. Total, Referencia -->
          <v-row class="mt-0">
            <v-col cols="12" sm="5" md="5" class="py-0">
              Referencia
              <v-text-field
                v-model="datosFactura.referencia"
                outlined
                dense
                :readonly="editar == false && esNuevaFC == 0"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="py-0">
              Imp. Total
              <v-text-field
                v-model="datosFactura.total"
                outlined
                dense
                type="number"
                :readonly="(editar == false && esNuevaFC == 0) || (esNuevaFC == 0 && (cadenaVacia(datosFactura.afip) || datosFactura.afip == 1))"
                @blur="setEncImpTotal()"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="py-0">
              Items
              <v-autocomplete
                v-model="selectedTipoFact"
                item-text="tipo_nombre"
                :items="tiposFact"
                hide-details
                outlined
                dense
                return-object
                :disabled="esNuevaFC == 0 && (selectedTipoFact.tipo_id == 1 || selectedTipoFact.tipo_id == 2)"
                @change="cargarArtsConceptos()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="py-0">
              Condición de Pago
              <v-autocomplete
                v-model="selectedCondPago"
                item-text="descripcion"
                :items="condicionesPago"
                hide-details
                outlined
                dense
                return-object
                :disabled="editar == false && esNuevaFC == 0"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <!-- 08-05-2023 por MMURILLO, agregamos el estado de control de Auditoría -->
          <v-row>
            <v-col cols="12" sm="2" md="2" v-if="esNuevaFC == 0">
              Estado Control
              <v-autocomplete
                v-model="selectedEstado"
                item-text="estado_control_nombre"
                :items="estadosControl"
                hide-details
                outlined
                dense
                return-object
                :disabled="(editar == false && esNuevaFC == 0) || tienePermiso == 0"
                @change="controlEstado()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              Moneda
              <v-autocomplete
                v-model="selectedMoneda"
                item-text="moneda_nombre"
                :items="monedas"
                hide-details
                outlined
                dense
                return-object
                :disabled="esNuevaFC == 0"
                @change="verificarCotizacion()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              Cotización
              <v-text-field
                v-model="cotizacion"
                :id="cotRef"
                :ref="cotRef"
                outlined
                dense
                type="number"
                hide-details
                :readonly="esNuevaFC == 0"
                @blur="verificarCotizacion()"
                @keypress.native.enter="$refs[cotRef].blur()"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Artículos -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="selectedTipoFact.tipo_id == 1">
            <v-col cols="12">
              <v-card
                elevation="2"
                outlined
              >
                <v-card-title>
                  Artículos
                  <v-btn icon @click="openModalArtConcept(1)" :disabled="editar == false && esNuevaFC == 0"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <!-- Agregar Artículos -->
                  <v-row v-if="(editar == true && esNuevaFC == 0) || esNuevaFC == 1">
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Cod. Artículo
                      <v-text-field
                        v-model="newCodArticulo"
                        outlined
                        dense
                        type="number"
                        :readonly="editar == false && esNuevaFC == 0"
                        @blur="completarArticulo()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-0">
                      Descripcion
                      <v-text-field
                        v-model="descripcionArticulo"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Precio Unitario
                      <v-text-field
                        v-model="precioArticulo"
                        outlined
                        dense
                        type="number"
                        :readonly="editar == false && esNuevaFC == 0"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Cantidad
                      <v-text-field
                        v-model="cantidadArticulo"
                        outlined
                        dense
                        type="number"
                        :readonly="editar == false && esNuevaFC == 0"
                        @keypress.enter="addArt({cantidad: cantidadArticulo, codigo_articulo_prov: newCodArticulo, articulo_codigo: newCodArticulo, articulo_nombre: descripcionArticulo, formula: formulaArticulo, gravamen_codigo: gravamenArticulo, opcion: 2, precio_s_iva: precioArticulo, proveedor_articulo_codigo: newCodArticulo, codigo_proveedor: provCodigoArticulo})"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0 pt-5">
                      <v-btn 
                        color="primary" 
                        title="Agregar Artículo" 
                        :disabled="editar == false && esNuevaFC == 0"
                        @click="addArt({cantidad: cantidadArticulo, codigo_articulo_prov: newCodArticulo, articulo_codigo: newCodArticulo, articulo_nombre: descripcionArticulo, formula: formulaArticulo, gravamen_codigo: gravamenArticulo, opcion: 2, precio_s_iva: precioArticulo, proveedor_articulo_codigo: newCodArticulo, codigo_proveedor: provCodigoArticulo})"
                      >
                        Agregar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- Tabla de Artículos -->
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        class="cebra elevation-2 mt-2"
                        :headers="heaaders"
                        :items="articulosFactura"
                        :loading="loaad"
                        dense
                        :search="searrch"
                      >
                        <!-- Buscador en el datatable -->
                        <template v-slot:top>
                          <v-row class="d-flex justify-end pa-2" no-gutters>
                            <v-col cols="6" sm="3">
                              <SearchDataTable
                                v-model="searrch"
                              />
                            </v-col>
                          </v-row>
                        </template>
                        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                        <template
                          v-for="header in heaaders.filter((header) => header.hasOwnProperty('formatter'))"
                          v-slot:[`item.${header.value}`]="{ value }"
                        >
                          {{ header.formatter(value) }}
                        </template>
                        <!-- Items -->
                        <template v-slot:[`item.tasa`]="{ item }">
                          % {{ item.tasa }}
                        </template>
                        <template v-slot:[`item.cantidad`]="{ item }">
                          <v-row class="d-flex justify-center" no-gutters>
                            <v-text-field v-model="item.cantidad" type="number" @blur="recalcularTotales(0)" outlined dense hide-details style="width: 50px" :readonly="editar == false && esNuevaFC == 0"></v-text-field>
                          </v-row>
                        </template>
                        <template v-slot:[`item.precio_unitario`]="{ item }">
                          <v-row class="d-flex justify-center" no-gutters>
                            <v-text-field v-model="item.precio_unitario" type="number" @blur="recalcularTotales(0)" outlined dense hide-details style="width: 120px" :readonly="editar == false && esNuevaFC == 0"></v-text-field>
                          </v-row>
                        </template>
                        <template v-slot:[`item.iva`]="{ item }">
                          <v-row class="d-flex justify-center" no-gutters>
                            <v-text-field v-model="item.iva" type="number" @blur="recalcularTotales(1)" outlined dense hide-details style="width: 120px" :readonly="editar == false && esNuevaFC == 0"></v-text-field>
                          </v-row>
                        </template>
                        <!-- <template v-slot:[`item.total_imp_interno`]="{ item }">
                          <v-row class="d-flex justify-center" no-gutters>
                            <v-text-field prefix="$" v-model="item.total_imp_interno" type="number" @blur="recalcularTotales(1)" outlined dense hide-details style="width: 20px" :readonly="editar == false && esNuevaFC == 0"></v-text-field>
                          </v-row>
                        </template> -->
                        <!-- Acciones y modal para confirmar -->
                        <template v-slot:[`item.acciones`]="{ item }">
                          <v-btn icon small @click="quitarArticulo(item)" :disabled="editar == false && esNuevaFC == 0"><v-icon small color="error" title="Quitar">fas fa-times</v-icon></v-btn>
                        </template>
                        <!-- ultima row para el total -->
                        <template slot="body.append">
                          <tr class="font-weight-bold">
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th class="text-center">Subtotal: ${{ Intl.NumberFormat("de-DE").format(subTotal) }}</th>
                          </tr>
                          <tr class="font-weight-bold">
                            <th class="text-center">Iva 21%: {{ Intl.NumberFormat("de-DE").format(totalIva21) }}</th>
                            <th class="text-center">Iva 10,5%: {{ Intl.NumberFormat("de-DE").format(totalIva105) }}</th>
                            <th class="text-center">Iva 27%: {{ Intl.NumberFormat("de-DE").format(totalIva27) }}</th>
                            <th></th>
                            <th class="text-center">Imp. Int.: {{ Intl.NumberFormat("de-DE").format(totalImpInterno) }}</th>
                            <th class="text-center">Ret. Especiales: {{ Intl.NumberFormat("de-DE").format(totalRetEspeciales) }}</th>
                            <th></th>
                            <th class="text-center">Total: {{ Intl.NumberFormat("de-DE").format(totalFinal) }}</th>
                          </tr>
                        </template>
                        <!-- Msj que se mostrara si no existen resultados -->
                        <template v-slot:no-data>
                          <v-alert
                            class="mx-auto mt-4"
                            max-width="400"
                            type="warning"
                            border="left"
                            dense
                            text
                          >
                            No hay datos para los filtros seleccionados
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- Conceptos -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="selectedTipoFact.tipo_id == 2">
            <v-col cols="12">
              <v-card
                elevation="2"
                outlined
              >
                <v-card-title>
                  Conceptos
                  <v-btn icon @click="openModalArtConcept(2)" :disabled="editar == false && esNuevaFC == 0"><v-icon color="success" small>fas fa-search</v-icon></v-btn>
                </v-card-title>
                <v-card-text>
                  <!-- Agregar Conceptos -->
                  <v-row v-if="(editar == true && esNuevaFC == 0) || esNuevaFC == 1">
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Cod. Concepto
                      <v-text-field
                        v-model="newCodConcepto"
                        outlined
                        dense
                        :readonly="editar == false && esNuevaFC == 0"
                        @blur="completarConcepto()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-0">
                      Descripcion
                      <v-text-field
                        v-model="descripcionConcepto"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Precio Unitario
                      <v-text-field
                        v-model="precioConcepto"
                        outlined
                        dense
                        :readonly="editar == false && esNuevaFC == 0"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Cantidad
                      <v-text-field
                        v-model="cantidadConcepto"
                        outlined
                        dense
                        type="number"
                        :readonly="editar == false && esNuevaFC == 0"
                        @keypress.enter="addConcepto({cantidad: cantidadConcepto, codigo_concepto_prov: newCodConcepto, concepto_codigo: newCodConcepto, concepto_nombre: descripcionConcepto, formula: formulaConcepto, gravamen_codigo: gravamenConcepto, opcion: 2, precio_s_iva: precioConcepto, proveedor_concepto_codigo: newCodConcepto, codigo_proveedor: provCodigoConcepto})"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0 pt-5">
                      <v-btn 
                        color="primary" 
                        title="Agregar Concepto" 
                        :disabled="editar == false && esNuevaFC == 0"
                        @click="addConcepto({cantidad: cantidadConcepto, codigo_concepto_prov: newCodConcepto, concepto_codigo: newCodConcepto, concepto_nombre: descripcionConcepto, formula: formulaConcepto, gravamen_codigo: gravamenConcepto, opcion: 2, precio_s_iva: precioConcepto, proveedor_concepto_codigo: newCodConcepto, codigo_proveedor: provCodigoConcepto})"
                      >
                        Agregar
                      </v-btn>
                    </v-col>
                  </v-row>
                  <!-- Tabla de Conceptos -->
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        class="cebra elevation-2 mt-2"
                        :headers="headers"
                        :items="conceptosFactura"
                        :loading="load"
                        dense
                        :search="search"
                        :disabled="editar == false && esNuevaFC == 0"
                        item-key="concepto_codigo"
                      >
                        <!-- Buscador en el datatable -->
                        <template v-slot:top>
                          <v-row class="d-flex justify-end pa-2" no-gutters>
                            <v-col cols="6" sm="3">
                              <SearchDataTable
                                v-model="search"
                              />
                            </v-col>
                          </v-row>
                        </template>
                        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                        <template
                          v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
                          v-slot:[`item.${header.value}`]="{ value }"
                        >
                          {{ header.formatter(value) }}
                        </template>
                        <!-- Items -->
                        <template v-slot:[`item.tasa`]="{ item }">
                          % {{ item.tasa }}
                        </template>
                        <template v-slot:[`item.cantidad`]="{ item }">
                          <v-row class="d-flex justify-center" no-gutters>
                            <v-text-field v-model="item.cantidad" type="number" @blur="recalcularTotales(0)" outlined dense hide-details style="width: 50px" :readonly="editar == false && esNuevaFC == 0"></v-text-field>
                          </v-row>
                        </template>
                        <template v-slot:[`item.precio_unitario`]="{ item }">
                          <v-row class="d-flex justify-center" no-gutters>
                            <v-text-field v-model="item.precio_unitario" type="number" @blur="recalcularTotales(0)" outlined dense hide-details style="width: 120px" :readonly="editar == false && esNuevaFC == 0"></v-text-field>
                          </v-row>
                        </template>
                        <template v-slot:[`item.iva`]="{ item }">
                          <v-row class="d-flex justify-center" no-gutters>
                            <v-text-field v-model="item.iva" type="number" @blur="recalcularTotales(1)" outlined dense hide-details style="width: 120px" :readonly="editar == false && esNuevaFC == 0"></v-text-field>
                          </v-row>
                        </template>
                        <!-- <template v-slot:[`item.total_imp_interno`]="{ item }">
                          <v-row class="d-flex justify-center" no-gutters>
                            <v-text-field prefix="%" v-model="item.total_imp_interno" type="number" @blur="recalcularTotales(1)" outlined dense hide-details style="width: 20px" :readonly="editar == false && esNuevaFC == 0"></v-text-field>
                          </v-row>
                        </template> -->
                        <!-- Acciones -->
                        <template v-slot:[`item.acciones`]="{ item }">
                          <v-btn icon small @click="quitarConcepto(item)" :disabled="editar == false && esNuevaFC == 0"><v-icon small color="error" title="Quitar">fas fa-times</v-icon></v-btn>
                        </template>
                        <!-- ultima row para el total -->
                        <template slot="body.append">
                          <tr class="font-weight-bold">
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th class="text-center">Subtotal: ${{ Intl.NumberFormat("de-DE").format(subTotal) }}</th>
                          </tr>
                          <tr class="font-weight-bold">
                            <th class="text-center">Iva 21%: {{ Intl.NumberFormat("de-DE").format(totalIva21) }}</th>
                            <th class="text-center">Iva 10,5%: {{ Intl.NumberFormat("de-DE").format(totalIva105) }}</th>
                            <th class="text-center">Iva 27%: {{ Intl.NumberFormat("de-DE").format(totalIva27) }}</th>
                            <th></th>
                            <th class="text-center">Imp. Int.: {{ Intl.NumberFormat("de-DE").format(totalImpInterno) }}</th>
                            <th class="text-center">Ret. Especiales: {{ Intl.NumberFormat("de-DE").format(totalRetEspeciales) }}</th>
                            <th></th>
                            <th class="text-center">Total: {{ Intl.NumberFormat("de-DE").format(totalFinal) }}</th>
                          </tr>
                        </template>
                        <!-- Msj que se mostrara si no existen resultados -->
                        <template v-slot:no-data>
                          <v-alert
                            class="mx-auto mt-4"
                            max-width="400"
                            type="warning"
                            border="left"
                            dense
                            text
                          >
                            No hay datos para los filtros seleccionados
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- Retenciones Especiales -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12">
              <v-card
                elevation="2"
                outlined
              >
                <v-card-title>
                  Retenciones Especiales
                </v-card-title>
                <v-card-text class="pt-2">
                  <!-- Agregar Retenciones Especiales -->
                  <v-row v-if="(editar == true && esNuevaFC == 0) || esNuevaFC == 1">
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Código Ret. Esp.
                      <v-text-field
                        v-model="codigoRetEspecial"
                        outlined
                        dense
                        :readonly="editar == false && esNuevaFC == 0"
                        @blur="completarRetEspecial(1)"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="py-0">
                      Retencion
                      <v-autocomplete
                        v-model="selectedRetencion"
                        item-text="descripcion"
                        :items="retencionesEspeciales"
                        hide-details
                        outlined
                        dense
                        return-object
                        :disabled="editar == false && esNuevaFC == 0"
                        @change="completarRetEspecial(2)"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Tipo Ret. Esp.
                      <v-text-field
                        v-model="tipoRetEspecial"
                        outlined
                        dense
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Importe
                      <v-text-field
                        v-model="importeRetEspecial"
                        outlined
                        dense
                        type="number"
                        :readonly="editar == false && esNuevaFC == 0"
                        @keypress.enter="addRetencion()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0 pt-5">
                      <v-btn color="primary" title="Agregar Retención" :disabled="editar == false && esNuevaFC == 0" @click="addRetencion()">Agregar</v-btn>
                    </v-col>
                  </v-row>
                  <!-- Tabla de Retenciones Especiales -->
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        class="cebra elevation-2 mt-2"
                        :headers="headerss"
                        :items="listadoRetenciones"
                        :loading="loadd"
                        dense
                        :search="searchh"
                      >
                        <!-- Buscador en el datatable -->
                        <template v-slot:top>
                          <v-row class="d-flex justify-end pa-2" no-gutters>
                            <v-col cols="6" sm="3">
                              <SearchDataTable
                                v-model="searchh"
                              />
                            </v-col>
                          </v-row>
                        </template>
                        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                        <template
                          v-for="header in headerss.filter((header) => header.hasOwnProperty('formatter'))"
                          v-slot:[`item.${header.value}`]="{ value }"
                        >
                          {{ header.formatter(value) }}
                        </template>
                        <!-- Acciones y modal para confirmar -->
                        <template v-slot:[`item.acciones`]="{ item }">
                          <v-btn icon small @click="quitarRetencion(item)" :disabled="editar == false && esNuevaFC == 0"><v-icon small color="error" title="Quitar Retencion">fas fa-times</v-icon></v-btn>
                        </template>
                        <!-- ultima row para el total -->
                        <template slot="body.append">
                          <tr class="font-weight-bold">
                            <th></th>
                            <th></th>
                            <th></th>
                            <th class="text-center">Total: ${{ Intl.NumberFormat("de-DE").format(totalRetEspeciales) }}</th>
                            <th></th>
                          </tr>
                        </template>
                        <!-- Msj que se mostrara si no existen resultados -->
                        <template v-slot:no-data>
                          <v-alert
                            class="mx-auto mt-4"
                            max-width="400"
                            type="warning"
                            border="left"
                            dense
                            text
                          >
                            No hay datos para los filtros seleccionados
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- Pagos -->
          <v-row :no-gutters="$vuetify.breakpoint.xs">
            <v-col cols="12">
              <v-card
                elevation="2"
                outlined
              >
                <v-card-title>
                  Pagos
                </v-card-title>
                <v-card-text>
                  <!-- Forma de Ingreso -->
                  <v-row v-if="(editar == true && esNuevaFC == 0) || esNuevaFC == 1">
                    <v-col cols="12">
                      <v-switch
                        v-model="pagoFormaIngreso"
                        class="d-flex align-center py-0 my-0"
                        hide-details
                        dense
                        :label="`Forma de Ingreso: ${pagoNombreForIngreso}`"
                        :disabled="editar == false && esNuevaFC == 0"
                      ></v-switch>
                    </v-col>
                  </v-row>
                  <!-- Agregar Pago -->
                  <v-row class="pt-4" v-if="(editar == true && esNuevaFC == 0) || esNuevaFC == 1">
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Fecha
                      <FechaPicker
                        v-model="fechaPago"
                        ref="fechaPagoEstimada"
                        :disabled="editar == false && esNuevaFC == 0"
                        @blur="validaFechaPago()"
                      />
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Porcentaje
                      <v-text-field
                        v-model="porcentajePago"
                        outlined
                        dense
                        type="number"
                        prefix="%"
                        :readonly="pagoFormaIngreso == true || (editar == false && esNuevaFC == 0)"
                        @blur="validaPorcentaje()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Importe
                      <v-text-field
                        v-model="importePago"
                        outlined
                        dense
                        type="number"
                        prefix="$"
                        :readonly="pagoFormaIngreso == false || (editar == false && esNuevaFC == 0)"
                        @blur="validaImportePago()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" class="py-0">
                      Forma de Pago
                      <v-autocomplete
                        v-model="selectedFP"
                        item-text="forma_pago_nombre"
                        :items="formasPago"
                        hide-details
                        outlined
                        dense
                        return-object
                        :disabled="editar == false && esNuevaFC == 0"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" class="py-0">
                      Tipo de Pago
                      <v-autocomplete
                        v-model="selectedTipoPago"
                        item-text="tipo_pago_nombre"
                        :items="tiposPago"
                        hide-details
                        outlined
                        dense
                        return-object
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" class="py-0 pt-5">
                      <v-btn title="Agregar Pago" icon :disabled="editar == false && esNuevaFC == 0" @click="addPago()"><v-icon color="success">far fa-check-circle</v-icon></v-btn>
                    </v-col>
                  </v-row>
                  <!-- Tabla de Pagos -->
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        class="cebra elevation-2 mt-2"
                        :headers="headders"
                        :items="listadoPagos"
                        :loading="looad"
                        dense
                        :search="seaarch"
                        :disabled="editar == false && esNuevaFC == 0"
                      >
                        <!-- Buscador en el datatable -->
                        <template v-slot:top>
                          <v-row class="d-flex justify-end pa-2" no-gutters>
                            <v-col cols="6" sm="3">
                              <SearchDataTable
                                v-model="seaarch"
                              />
                            </v-col>
                          </v-row>
                        </template>
                        <!-- este template se usa para aplicar formato a las columnas que tengan la propeidad formatter -->
                        <template
                          v-for="header in headders.filter((header) => header.hasOwnProperty('formatter'))"
                          v-slot:[`item.${header.value}`]="{ value }"
                        >
                          {{ header.formatter(value) }}
                        </template>
                        <!-- Items -->
                        <template v-slot:[`item.porcentaje_pago`]="{ item }">
                          {{ item.porcentaje_pago }}%
                        </template>
                        <!-- Acciones -->
                        <template v-slot:[`item.acciones`]="{ item }">
                          <v-btn icon small @click="quitarPago(item)" :disabled="editar == false && esNuevaFC == 0" v-if="item.pago == 0"><v-icon small color="error" title="Quitar pago">fas fa-times</v-icon></v-btn>
                        </template>
                        <!-- ultima row para el total -->
                        <template slot="body.append">
                          <tr class="font-weight-bold">
                            <th></th>
                            <th class="text-center">{{ totalFinalPorcentaje }}%</th>
                            <th></th>
                            <th></th>
                            <th class="text-center">${{ Intl.NumberFormat("de-DE").format(totalFinalPagos) }}</th>
                            <th></th>
                          </tr>
                        </template>
                        <!-- Msj que se mostrara si no existen resultados -->
                        <template v-slot:no-data>
                          <v-alert
                            class="mx-auto mt-4"
                            max-width="400"
                            type="warning"
                            border="left"
                            dense
                            text
                          >
                            No hay datos para los filtros seleccionados
                          </v-alert>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- Botones Confirmar - Cancelar -->
          <v-row :no-gutters="$vuetify.breakpoint.xs" class="justify-end pt-6">
            <v-btn color="error" class="mr-2" @click="volverInicio()">Volver</v-btn>
            <BtnConfirmar
              texto="¿Está seguro de marcar la Factura como VERIFICADA?"
              @action="marcarComoVerificada()"
              color="accent"
              nombre="Marcar Verificada"
              icono="fas fa-check"
              clase="mr-2"
              v-if="esNuevaFC == 0 && datosFactura.estado_codigo == 1"
            />
            <BtnConfirmar
              texto="¿Está seguro de guardar los cambios realizados?"
              @action="guardarCambios()"
              v-if="editar == true"
            />
            <BtnConfirmar
              texto="¿Está seguro de guardar la Factura de Compra?"
              @action="crearFC()"
              v-if="esNuevaFC == 1"
            />
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- Modal Articulos - Conceptos -->
    <ModalArtConcepProv
      :activo="modalArtConcept"
      :opcion="opcionBusqueda"
      :proveedorCodigo="datosFactura.proveedor_codigo"
      :empresaId="datosFactura.empresa_codigo"
      :articulos="articulosParam"
      :conceptos="conceptosParam"
      @cerrarModalArtConcep="closeModalArtConcep"
      @agregarArticulo="addArt"
      @agregarConcepto="addConcepto"
    />
  </v-row>
</template>

<script>
import moment from 'moment'
import FechaPicker from '../../components/util/FechaPicker.vue'
import SearchDataTable from '../../components/util/SearchDataTable.vue'
import { format_money, convertDecimals, convertDecimalsPlus, roundNumber, cadenaVacia } from '../../util/utils'
import ModalArtConcepProv from '../../components/administracion/ModalArtConcepProv.vue'
import BtnConfirmar from '../../components/util/BtnConfirmar.vue'
export default {
  components: {
    FechaPicker,
    SearchDataTable,
    ModalArtConcepProv,
    BtnConfirmar
  },
  data() {
    return {
      cadenaVacia: cadenaVacia,
      idFactura: '',
      esNuevaFC: 0,
      editar: false,
      datosFactura: {
        con_archivo: 0,
        emision: '',
        empresa_codigo: 0,
        empresa_nombre: '',
        estado_codigo: 0,
        estado_nombre: '',
        factura_id: this.$route.params.id,
        fecha: moment(new Date()).format('DD/MM/YYYY'),
        fecha_contable: '',
        if_anula: 0,
        if_modifica: 0,
        numero: 0,
        proveedor_codigo: 0,
        proveedor_nombre: '',
        referencia: '',
        sucursal_codigo: 0,
        sucursal_nombre: '',
        tipo_factura: '',
        total: 0,
        usuario: '',
        tipo_factura_id: ''
      },
      selectedProveedor: {},
      proveedores: [],
      selectedEmpresa: {},
      empresas: [],
      selectedSucursal: {},
      sucursales: [],
      selectedTipoFact: {},
      tiposFact: [{tipo_id: 1, tipo_nombre: 'Artículos'}, {tipo_id: 2, tipo_nombre: 'Conceptos'}, {tipo_id: 0, tipo_nombre: 'Sin Asignar'}],
      selectedTipoFactura: {},
      tiposFactura: [],
      newCodConcepto: '',
      descripcionConcepto: '',
      precioConcepto: '',
      precioArticulo: '',
      cantidadConcepto: '1',
      cantidadArticulo: '1',
      formulaConcepto: '',
      formulaArticulo: '',
      gravamenConcepto: '',
      gravamenArticulo: '',
      provCodigoConcepto: '',
      provCodigoArticulo: '',
      conceptosFactura: [],
      load: false,
      search: '',
      headers: [
        { text: 'Cod. Concepto', align: 'center', value: 'concepto_codigo' },
        { text: 'Nombre', align: 'center', value: 'concepto_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Precio', align: 'center', value: 'precio_unitario', formatter: format_money },
        { text: 'Tasa', align: 'center', value: 'tasa' },
        { text: 'Importe', align: 'center', value: 'precio_total', formatter: format_money },
        { text: 'Iva', align: 'center', value: 'iva', formatter: format_money },
        //{ text: '% Imp. Interno', align: 'center', value: 'porc_imp_interno' },
        //{ text: 'Imp. Interno', align: 'center', value: 'total_imp_interno', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      totalCantidadConcepto: 0,
      totalCantidadArticulo: 0,
      subTotal: 0,
      totalIva21: 0,
      totalIva105: 0,
      totalIva27: 0,
      totalIvaFinal: 0,
      totalImpInterno: 0,
      totalRetEspeciales: 0,
      totalFinal: 0,
      totalNetoFinal: 0,
      listadoTotales: [
        {
          codigo: 0,
          totalCantidadCadena: 'Total: ',
          totalCantidadValor: '0',
          totalCadena: 'SubTotal: ',
          totalValor: '0'
        },
        {
          codigo: 1,
          totalCantidadCadena: '',
          totalCantidadValor: '',
          totalCadena: 'Iva 21%: ',
          totalValor: '0'
        },
        {
          codigo: 2,
          totalCantidadCadena: '',
          totalCantidadValor: '',
          totalCadena: 'Iva 10,5%: ',
          totalValor: '0'
        },
        {
          codigo: 3,
          totalCantidadCadena: '',
          totalCantidadValor: '',
          totalCadena: 'Iva 27%',
          totalValor: '0'
        },
        {
          codigo: 4,
          totalCantidadCadena: '',
          totalCantidadValor: '',
          totalCadena: 'Imp. Interno: ',
          totalValor: '0'
        },
        {
          codigo: 5,
          totalCantidadCadena: '',
          totalCantidadValor: '',
          totalCadena: 'Ret. Especiales: ',
          totalValor: '0'
        },
        {
          codigo: 6,
          totalCantidadCadena: '',
          totalCantidadValor: '',
          totalCadena: 'Total: ',
          totalValor: '0'
        },
      ],
      selectedRetencion: {},
      retencionesEspeciales: [],
      tipoRetEspecial: '',
      codigoRetEspecial: '',
      importeRetEspecial: '',
      loadd: false,
      searchh: '',
      listadoRetenciones: [],
      headerss: [
        { text: 'Descripcion', align: 'center', value: 'retencion_nombre' },
        { text: 'Codigo', align: 'center', value: 'retencion_codigo' },
        { text: 'Tipo', align: 'center', value: 'retencion_tipo' },
        { text: 'Importe', align: 'center', value: 'retencion_total', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      pagoFormaIngreso: false,
      pagoNombreForIngreso: 'Porcentaje',
      fechaPago: moment(new Date()).format('DD/MM/YYYY'),
      porcentajePago: '',
      importePago: '',
      formasPago: [],
      selectedFP: {},
      headders: [
        { text: 'Fecha', align: 'center', value: 'fecha_pago' },
        { text: 'Porcentaje', align: 'center', value: 'porcentaje_pago', formatter: format_money },
        { text: 'Forma de Pago', align: 'center', value: 'forma_pago' },
        { text: 'Tipo de Pago', align: 'center', value: 'tipo_pago_nombre' },
        { text: 'Monto', align: 'center', value: 'pago_total', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      listadoPagos: [],
      looad: false,
      seaarch: '',
      totalFinalPorcentaje: 0,
      totalFinalPagos: 0,
      newCodArticulo: '',
      descripcionArticulo: '',
      precioArticulo: '',
      cantidadArticulo: '1',
      heaaders: [
        { text: 'Cod. Artículo', align: 'center', value: 'articulo_codigo' },
        { text: 'Nombre', align: 'center', value: 'articulo_nombre' },
        { text: 'Cantidad', align: 'center', value: 'cantidad' },
        { text: 'Precio', align: 'center', value: 'precio_unitario', formatter: format_money },
        { text: 'Tasa', align: 'center', value: 'tasa' },
        { text: 'Importe', align: 'center', value: 'precio_total', formatter: format_money },
        { text: 'Iva', align: 'center', value: 'iva', formatter: format_money },
        //{ text: '% Imp. Interno', align: 'center', value: 'porc_imp_interno' },
        //{ text: 'Imp. Interno', align: 'center', value: 'total_imp_interno', formatter: format_money },
        { text: 'Acciones', align: 'center', value: 'acciones' }
      ],
      articulosFactura: [],
      loaad: false,
      searrch: '',
      totalCantidadArticulos: 0,
      modalArtConcept: false,
      opcionBusqueda: 0,
      articulosParam: [],
      conceptosParam: [],
      multiCuentaProv: [],
      selectedMultiProv: {},
      selectedCondPago: {},
      condicionesPago: [],
      pagada: 0,
      selectedEstado: {},
      estadosControl: [],
      tienePermiso: 0,
      monedas: [],
      selectedMoneda: {},
      cotizacion: '',
      cotRef: 'cotRef',
      selectedTipoPago: {},
      tiposPago: []
    }
  },
  created () {
    if (Object.keys(this.$route.params).length == 0){
      // es nueva factura
      this.esNuevaFC = 1
    }else{
      // estan consultando una factura particular
      this.esNuevaFC = 0
      this.idFactura = this.$route.params.id
    }
    this.iniciarFormulario()
  },
  computed: {
    
  },
  methods: {
    async iniciarFormulario(){
      this.$store.state.loading = true
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.empresas = JSON.parse(localStorage.getItem('empresas'))
      // obtengo las condiciones de pago
      let condicionesPeticion = await this.$store.dispatch('facturasCompra/get_condiciones_pago')
      if (condicionesPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: condicionesPeticion.msj,
          color: 'error',
        })
        return
      }
      this.condicionesPago = condicionesPeticion.condiciones
      this.estadosControl = condicionesPeticion.estados
      this.monedas = condicionesPeticion.monedas
      this.tiposPago = condicionesPeticion.tipos_pagos
      // es una nueva factura de compra?
      if (this.esNuevaFC == 0){
        // obtengo los datos de la factura de compra
        this.$store.dispatch('facturasCompra/get_an_fc_data', {idFactura: this.idFactura})
          .then(response => {
            this.$store.state.loading = false
            if (response.resultado == 1){
              // 08-05-2023 por MMURILLO, tiene permiso?
              this.tienePermiso = response.permiso
              // encabezado
              if (response.datos.length > 0){
                this.datosFactura = response.datos[0]
                // formateo la fecha y fecha contable
                this.datosFactura.fecha = moment(this.datosFactura.fecha).format('DD/MM/YYYY')
                if (this.datosFactura.fecha_contable != null && this.datosFactura.fecha_contable != undefined){
                  this.datosFactura.fecha_contable = moment(this.datosFactura.fecha_contable).format('DD/MM/YYYY')
                }
                // proveedor
                this.selectedProveedor = {
                  proveedor_codigo: this.datosFactura.proveedor_codigo,
                  proveedor_nombre: this.datosFactura.proveedor_nombre,
                  proveedor_cuit: this.datosFactura.proveedor_cuit,
                  multicuenta: this.datosFactura.multicuenta
                }
                // proveedor multicuenta
                this.multiCuentaProv = []
                if (parseInt(this.datosFactura.multicuenta) > 0){
                  let unMultiProv = {
                    proveedor_codigo: this.datosFactura.proveedor_bejerman_codigo,
                    proveedor_nombre: this.datosFactura.proveedor_bejerman_nombre,
                    cuit: this.datosFactura.proveedor_cuit
                  }
                  this.multiCuentaProv.push(unMultiProv)
                  this.selectedMultiProv = unMultiProv
                }
                this.proveedores.push(this.selectedProveedor)
                // empresa
                this.selectedEmpresa = this.empresas.filter(element => element.id == this.datosFactura.empresa_codigo)[0]
                this.selectedSucursal = this.sucursales.filter(element => element.id == this.datosFactura.sucursal_codigo)[0]
                // sucursal
                if (this.datosFactura.sucursal_codigo != null && this.datosFactura.sucursal_codigo != undefined && this.datosFactura.sucursal_codigo.length > 0){
                  this.selectedSucursal = this.sucursales.filter(element => element.id == this.datosFactura.sucursal_codigo)[0]
                }
                // tipos de comprobantes
                this.tiposFactura = response.tiposFacturas
                this.selectedTipoFactura = this.tiposFactura.filter(element => element.tipo_id == this.datosFactura.tipo_factura_id)[0]
                // tipo de factura
                this.selectedTipoFact = this.tiposFact.filter(element => element.tipo_id == this.datosFactura.tipo_ingreso)[0]
                // 08-05-2023 por MMURILLO, estado de control
                this.selectedEstado = this.estadosControl.filter(element => element.estado_control_id == this.datosFactura.estado_control_id)[0]
                // 12-05-2023 por MMURILLO, moneda
                this.selectedMoneda = this.monedas.filter(element => element.moneda_id == this.datosFactura.moneda_id)[0]
                // cotización
                if (roundNumber(this.datosFactura.cotizacion, 2) > 0) this.cotizacion = roundNumber(this.datosFactura.cotizacion, 2)
                // detalles de factura
                if (response.detalles.length > 0){
                  for (let id in response.detalles){
                    let unDetalle = {
                      cantidad: response.detalles[id].cantidad,
                      precio_unitario: response.detalles[id].precio,
                      tasa: this.getTasa(response.detalles[id].tasa),
                      precio_total: response.detalles[id].importe,
                      iva: response.detalles[id].iva,
                      porc_imp_interno: response.detalles[id].tasa_impuesto,
                      total_imp_interno: this.convertDecimals(response.detalles[id].impuesto),
                      neto: response.detalles[id].neto,
                      gravamen: response.detalles[id].gravamen_codigo,
                      id: response.detalles[id].id
                    }
                    if (this.selectedTipoFact.tipo_id == 1){
                      // son articulos
                      unDetalle.articulo_codigo = response.detalles[id].concepto_codigo
                      unDetalle.articulo_nombre = response.detalles[id].concepto_nombre
                    }else{
                      // son conceptos
                      unDetalle.concepto_codigo = response.detalles[id].concepto_codigo
                      unDetalle.concepto_nombre = response.detalles[id].concepto_nombre
                    }
                    unDetalle.tasa = unDetalle.tasa.toString().replace('.', ',')
                    unDetalle.porc_imp_interno = parseFloat(unDetalle.porc_imp_interno.toString().replace(',', '.')).toFixed(2)
                    if (this.selectedTipoFact.tipo_id == 1){
                      this.articulosFactura.push(unDetalle)
                      this.opcionBusqueda = 1
                    }else{
                      this.conceptosFactura.push(unDetalle)
                      this.opcionBusqueda = 2
                    }
                  }
                }
                // retenciones especiales
                if (response.retenciones.length > 0){
                  for (let id in response.retenciones){
                    let unaRetencion = {
                      retencion_nombre: response.retenciones[id].descripcion,
                      retencion_codigo: response.retenciones[id].codigo,
                      retencion_tipo: response.retenciones[id].tipo,
                      retencion_total: response.retenciones[id].importe,
                      id: response.retenciones[id].id,
                      retencion_id: response.retenciones[id].id
                    }
                    this.listadoRetenciones.push(unaRetencion)
                  }
                }
                // periodos de pago
                if (response.periodosPagos.length > 0){
                  // esta pagada?
                  let estaPago = response.periodosPagos.filter(element => element.pago == 1)
                  if (estaPago.length > 0) this.pagada = 1
                  for (let id in response.periodosPagos){
                    // 12-05-2023 por MMURILLO, determino el tipo de pago
                    let unTP = this.tiposPago.filter(element => element.tipo_pago_id == response.periodosPagos[id].tipo_pago_id && parseInt(response.periodosPagos[id].tipo_pago_id) > 0)
                    let unPeriodo = {
                      fecha_pago: response.periodosPagos[id].fecha,
                      pago: response.periodosPagos[id].pago,
                      porcentaje_pago: convertDecimals(response.periodosPagos[id].porcentaje),
                      forma_pago: response.periodosPagos[id].compra_fp_nombre,
                      forma_pago_id: response.periodosPagos[id].compra_fp_id,
                      pago_total: response.periodosPagos[id].importe,
                      tipo_pago_id: unTP.length > 0 ? unTP[0].tipo_pago_id : 0,
                      tipo_pago_nombre: unTP.length > 0 ? unTP[0].tipo_pago_nombre : '',
                      id: response.periodosPagos[id].id
                    }
                    this.listadoPagos.push(unPeriodo)
                  }
                }
                // condicion de pago
                let unaCond = this.condicionesPago.filter(element => element.id == this.datosFactura.condicion_pago_bejerman)
                if (unaCond.length > 0) this.selectedCondPago = unaCond[0]
                // obtener articulos - conceptos x proveedor
                this.getArtsConcepProv()
              }else{
                this.$store.dispatch('show_snackbar', {
                  text: 'No se encontraron datos para la Factura seleccionada.',
                  color: 'warning',
                })
                return
              }
            }else{
              this.$store.dispatch('show_snackbar', {
                text: response.msj,
                color: 'error',
              })
              return
            }
          })
          .catch(error => {
            this.$store.state.loading = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se pudo obtener los datos de la factura: ' + error.message,
              color: 'error',
            })
            return
          })
      }else{
        await this.getRetencionesEspeciales()
        await this.getFormasPagoCompra()
        this.getFCTiposComprobantes()
        this.$store.state.loading = false
      }
    },
    setEncImpTotal(){
      this.datosFactura.total = parseFloat(this.datosFactura.total)
    },
    openModalArtConcept(opcion){
      this.opcionBusqueda = opcion
      this.modalArtConcept = true
    },
    closeModalArtConcep(){
      this.modalArtConcept = false
    },
    addArt(item){
      // controlar si ya existe en el detalle de articulos
      let unArticulo = this.articulosFactura.filter(element => element.articulo_codigo.toString().toUpperCase() == item.articulo_codigo.toString().toUpperCase())
      if (unArticulo.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'El Artículo seleccionado ya existe en el detalle de la Factura.',
          color: 'warning',
        })
        return
      }
      // controlar campos vacios
      if (item.opcion == 2){
        if (item.articulo_codigo.length == 0 || item.articulo_nombre.length == 0 || item.precio_s_iva.length == 0 || item.cantidad.length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Debe completar todos los datos del Artículo a agregar.',
            color: 'error',
          })
          return
        }
      }
      let totalItem = parseFloat(parseFloat(item.precio_s_iva)*parseInt(item.cantidad))
      let tasa = parseFloat(item.formula)
      let iva = 0
      if (this.datosFactura.tipo_factura != 'C' && this.datosFactura.tipo_factura != 'c'){
        iva = parseFloat((parseFloat(totalItem/(1+tasa)).toFixed(2))*tasa)
      }
      let neto = parseFloat(totalItem - iva)
      // agregar al listado
      let unDetalle = {
        articulo_codigo: item.articulo_codigo,
        articulo_nombre: item.articulo_nombre,
        cantidad: item.cantidad,
        precio_unitario: parseFloat(item.precio_s_iva.toString().replace(',', '.')).toFixed(2),
        tasa: this.getTasa(tasa),
        precio_total: totalItem.toFixed(2),
        iva: iva.toFixed(2),
        porc_imp_interno: 0,
        total_imp_interno: 0,
        neto: neto.toFixed(2),
        gravamen: item.gravamen_codigo,
        id: null
      }
      unDetalle.tasa = unDetalle.tasa.toString().replace('.', ',')
      unDetalle.porc_imp_interno = parseFloat(unDetalle.porc_imp_interno.toString().replace(',', '.')).toFixed(2)
      this.articulosFactura.push(unDetalle)
      if (item.opcion == 2){
        // limpio los campos
        this.newCodArticulo = ''
        this.descripcionArticulo = ''
        this.precioArticulo = ''
        this.cantidadArticulo = ''
        this.formulaArticulo = ''
        this.gravamenArticulo = ''
        this.provCodigoArticulo = ''
      }
    },
    completarConcepto(){
      if (this.newCodConcepto.length > 0){
        this.newCodConcepto = this.newCodConcepto.toUpperCase()
        let unConcepto = this.conceptosParam.filter(element => element.concepto_codigo.toUpperCase() == this.newCodConcepto.toUpperCase())
        if (unConcepto.length > 0){
          this.descripcionConcepto = unConcepto[0].concepto_nombre
          this.formulaConcepto = parseFloat(unConcepto[0].formula)
          this.gravamenConcepto = parseInt(unConcepto[0].gravamen_codigo)
          this.provCodigoConcepto = unConcepto[0].codigo_proveedor
        }else{
          this.$store.dispatch('show_snackbar', {
            text: 'No se reconoce el código del Concepto. Por favor, intente nuevamente.',
            color: 'info',
          })
          this.newCodConcepto = ''
          this.descripcionConcepto = ''
          this.precioConcepto = ''
          this.cantidadConcepto = ''
          this.formulaConcepto = ''
          this.gravamenConcepto = ''
          this.provCodigoConcepto = ''
          return
        }
      }
    },
    completarArticulo(){
      if (this.newCodArticulo.length > 0){
        this.newCodArticulo = this.newCodArticulo.toUpperCase()
        let unArticulo = this.articulosParam.filter(element => element.articulo_codigo.toUpperCase() == this.newCodArticulo.toUpperCase())
        if (unArticulo.length > 0){
          this.descripcionArticulo = unArticulo[0].articulo_nombre
          this.formulaArticulo = parseFloat(unArticulo[0].formula)
          this.gravamenArticulo = parseInt(unArticulo[0].gravamen_codigo)
          this.provCodigoArticulo = unArticulo[0].codigo_proveedor
        }else{
          this.$store.dispatch('show_snackbar', {
            text: 'No se reconoce el código del Artículo. Por favor, intente nuevamente.',
            color: 'info',
          })
          this.newCodArticulo = ''
          this.descripcionArticulo = ''
          this.precioArticulo = ''
          this.cantidadArticulo = ''
          this.formulaArticulo = ''
          this.gravamenArticulo = ''
          this.provCodigoArticulo = ''
        }
      }
    },
    getTasa(tasa){
      //return parseFloat(parseFloat(tasa.toString().replace(',', '.'))*100).toFixed(2)
      switch (tasa.toString().indexOf('105')){
        case -1: return parseFloat(tasa.toString().replace(',', '.')) == 21 ? tasa : parseFloat(tasa.toString().replace(',', '.')) == 10.5 ? tasa : parseFloat(tasa.toString().replace(',', '.'))*100
        default: return parseFloat(tasa.toString().replace(',', '.')) == 10.5 ? tasa : parseFloat(tasa.toString().replace(',', '.'))*100
      }
    },
    addConcepto(item){
      // controlar si ya existe en el detalle de conceptos
      let unConcepto = this.conceptosFactura.filter(element => element.concepto_codigo.toUpperCase() == item.concepto_codigo.toUpperCase())
      if (unConcepto.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'El concepto seleccionado ya existe en el detalle de la Factura.',
          color: 'warning',
        })
        return
      }
      // controlar campos vacios
      if (item.opcion == 2){
        if (item.concepto_codigo.length == 0 || item.concepto_nombre.length == 0 || item.precio_s_iva.length == 0 || item.cantidad.length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Debe completar todos los datos del Concepto a agregar.',
            color: 'error',
          })
          return
        }
      }
      let totalItem = parseFloat(parseFloat(item.precio_s_iva)*parseInt(item.cantidad)).toFixed(2)
      let tasa = parseFloat(item.formula)
      let iva = 0
      if (this.datosFactura.tipo_factura != 'C' && this.datosFactura.tipo_factura != 'c'){
        iva = parseFloat((parseFloat(totalItem/(1+tasa)).toFixed(2))*tasa)
      }
      let neto = parseFloat(totalItem - iva).toFixed(2)
      // agregar al listado
      let unDetalle = {
        concepto_codigo: item.concepto_codigo,
        concepto_nombre: item.concepto_nombre,
        cantidad: item.cantidad,
        precio_unitario: item.precio_s_iva,
        tasa: this.getTasa(tasa),
        precio_total: totalItem,
        iva: iva,
        porc_imp_interno: 0,
        total_imp_interno: 0,
        neto: neto,
        gravamen: item.gravamen_codigo,
        id: null
      }
      unDetalle.tasa = unDetalle.tasa.toString().replace('.', ',')
      unDetalle.porc_imp_interno = parseFloat(unDetalle.porc_imp_interno.toString().replace(',', '.')).toFixed(2)
      this.conceptosFactura.push(unDetalle)
      if (item.opcion == 2){
        // limpio los campos
        this.newCodConcepto = ''
        this.descripcionConcepto = ''
        this.precioConcepto = ''
        this.cantidadConcepto = ''
        this.formulaConcepto = ''
        this.gravamenConcepto = ''
        this.provCodigoConcepto = ''
      }
    },
    quitarConcepto(item){
      let pos = this.conceptosFactura.indexOf(item)
      this.conceptosFactura.splice(pos, 1)
    },
    quitarArticulo(item){
      let pos = this.articulosFactura.indexOf(item)
      this.articulosFactura.splice(pos, 1)
    },
    recalcularTotales(opcion){
      //this.totalCantidadConcepto = 0
      //this.totalCantidadArticulo = 0
      this.subTotal = 0
      this.totalIva21 = 0
      this.totalIva105 = 0
      this.totalIva27 = 0
      this.totalImpInterno = 0
      this.totalRetEspeciales = 0
      this.totalFinalPorcentaje = 0
      this.totalFinalPagos = 0
      this.totalIvaFinal = 0
      this.totalNetoFinal = 0
      //////////////////////// CONCEPTOS ////////////////////////
      if (this.conceptosFactura.length > 0){
        // actualizo los detalles de los conceptos primero
        for (let id in this.conceptosFactura){
          // cantidad
          if (this.conceptosFactura[id].cantidad.length == 0 || parseInt(this.conceptosFactura[id].cantidad) < 0){
            this.conceptosFactura[id].cantidad = 1
          }
          //this.totalCantidadConcepto = parseInt(this.totalCantidadConcepto) + parseInt(this.conceptosFactura[id].cantidad)
          // precio unitario
          if (this.conceptosFactura[id].precio_unitario.length == 0 || convertDecimals(this.conceptosFactura[id].precio_unitario) < 0){
            this.conceptosFactura[id].precio_unitario = 1
          }
          // importe total
          this.conceptosFactura[id].precio_total = convertDecimals(this.conceptosFactura[id].precio_unitario * this.conceptosFactura[id].cantidad)
          // iva
          let totalItem = convertDecimals(this.conceptosFactura[id].precio_total)
          let tasa = 0
          let iva = 0
          if (this.datosFactura.tipo_factura != 'C' && this.datosFactura.tipo_factura != 'c'){
            tasa = parseFloat(this.conceptosFactura[id].tasa.toString().replace(',', '.'))
            iva = convertDecimalsPlus(convertDecimals(totalItem) * (tasa/100))
          }
          if ((this.editar == true || this.esNuevaFC == 1) && opcion == 0) this.conceptosFactura[id].iva = convertDecimals(iva)
          this.conceptosFactura[id].tasa = this.getTasa(tasa).toString().replace('.', ',')
          // porc_imp_interno y total_imp_interno
          if (this.conceptosFactura[id].porc_imp_interno.length == 0 || convertDecimals(this.conceptosFactura[id].porc_imp_interno) < 0 || convertDecimals(this.conceptosFactura[id].porc_imp_interno) == 0){
            this.conceptosFactura[id].porc_imp_interno = 0
            //this.conceptosFactura[id].total_imp_interno = 0
          }else{
            let neto = convertDecimals(totalItem)
            //let tasaImpInt = parseFloat(this.conceptosFactura[id].porc_imp_interno.toString().replace(',', '.'))/100
            //let importeImpInt = neto * tasaImpInt
            this.conceptosFactura[id].neto = neto
            //this.conceptosFactura[id].total_imp_interno = importeImpInt.toFixed(2)
            //this.articulosFactura[id].porc_imp_interno = parseFloat(this.articulosFactura[id].porc_imp_interno.toString().replace(',', '.')).toFixed(2)
          }
          // acumulo el subTotal
          this.subTotal = convertDecimals(this.subTotal) + convertDecimals(totalItem)
          // acumulo los iva
          if (this.conceptosFactura[id].gravamen == 1){
            // del 21%
            this.totalIva21 = convertDecimals(this.totalIva21) + convertDecimals(this.conceptosFactura[id].iva)
          }else if (this.conceptosFactura[id].gravamen == 2){
            // del 10,5%
            this.totalIva105 = convertDecimals(this.totalIva105) + convertDecimals(this.conceptosFactura[id].iva)
          }else{
            // del 27%
            this.totalIva27 = convertDecimals(this.totalIva27) + convertDecimals(this.conceptosFactura[id].iva)
          }
          // acumulo el iva total
          this.totalIvaFinal = convertDecimals(this.totalIvaFinal) + convertDecimals(iva)
          // acumulo el neto
          this.totalNetoFinal = convertDecimals(this.totalNetoFinal) + convertDecimals(totalItem)
          // acumulo el total de impuesto interno
          this.totalImpInterno = convertDecimals(this.totalImpInterno) + convertDecimals(this.conceptosFactura[id].total_imp_interno)
        }
      }
      //////////////////////// ARTICULOS ////////////////////////
      if (this.articulosFactura.length > 0){
        for (let id in this.articulosFactura){
          // cantidad
          if (this.articulosFactura[id].cantidad.length == 0 || parseInt(this.articulosFactura[id].cantidad) < 0){
            this.articulosFactura[id].cantidad = 1
          }
          //this.totalCantidadArticulo = parseInt(this.totalCantidadArticulo) + parseInt(this.articulosFactura[id].cantidad)
          // precio unitario
          if (this.articulosFactura[id].precio_unitario.length == 0 || convertDecimals(this.articulosFactura[id].precio_unitario) < 0){
            this.articulosFactura[id].precio_unitario = 1
          }
          // importe total
          this.articulosFactura[id].precio_total = convertDecimals(convertDecimals(this.articulosFactura[id].precio_unitario) * parseInt(this.articulosFactura[id].cantidad))
          // iva
          let totalItem = convertDecimals(this.articulosFactura[id].precio_total)
          let tasa = 0
          let iva = 0
          if (this.datosFactura.tipo_factura != 'C' && this.datosFactura.tipo_factura != 'c'){
            tasa = parseFloat(this.articulosFactura[id].tasa.toString().replace(',', '.'))
            iva = convertDecimalsPlus(convertDecimals(totalItem) * (tasa/100))
          }
          if ((this.editar == true || this.esNuevaFC == 1) && opcion == 0) this.articulosFactura[id].iva = convertDecimals(iva)
          this.articulosFactura[id].tasa = this.getTasa(tasa).toString().replace('.', ',')
          // porc_imp_interno y total_imp_interno
          if (this.articulosFactura[id].porc_imp_interno.length == 0 || convertDecimals(this.articulosFactura[id].porc_imp_interno) < 0 || convertDecimals(this.articulosFactura[id].porc_imp_interno) == 0){
            this.articulosFactura[id].porc_imp_interno = 0
            //this.articulosFactura[id].total_imp_interno = 0
          }else{
            let neto = convertDecimals(totalItem)
            //let tasaImpInt = parseFloat(this.articulosFactura[id].porc_imp_interno.toString().replace(',', '.'))/100
            //let importeImpInt = neto * tasaImpInt
            this.articulosFactura[id].neto = neto
            //this.articulosFactura[id].total_imp_interno = importeImpInt.toFixed(2)
            //this.articulosFactura[id].porc_imp_interno = parseFloat(this.articulosFactura[id].porc_imp_interno.toString().replace(',', '.')).toFixed(2)
          }
          // acumulo el subTotal
          this.subTotal = convertDecimals(this.subTotal) + convertDecimals(totalItem)
          // acumulo los iva
          if (this.articulosFactura[id].gravamen == 1){
            // del 21%
            this.totalIva21 = convertDecimals(this.totalIva21) + convertDecimals(this.articulosFactura[id].iva)
          }else if (this.articulosFactura[id].gravamen == 2){
            // del 10,5%
            this.totalIva105 = convertDecimals(this.totalIva105) + convertDecimals(this.articulosFactura[id].iva)
          }else{
            // del 27%
            this.totalIva27 = convertDecimals(this.totalIva27) + convertDecimals(this.articulosFactura[id].iva)
          }
          // acumulo el iva total
          this.totalIvaFinal = convertDecimals(this.totalIvaFinal) + convertDecimals(iva)
          // acumulo el neto
          this.totalNetoFinal = convertDecimals(this.totalNetoFinal) + convertDecimals(totalItem)
          // acumulo el total de impuesto interno
          this.totalImpInterno = convertDecimals(this.totalImpInterno) + convertDecimals(this.articulosFactura[id].total_imp_interno)
        }
      }
      // retenciones especiales
      if (this.listadoRetenciones.length > 0){
        for (let id in this.listadoRetenciones){
          // acumulo el total de retenciones especiales
          this.totalRetEspeciales = convertDecimals(this.totalRetEspeciales) + convertDecimals(this.listadoRetenciones[id].retencion_total)
        }
      }
      // pagos
      if (this.listadoPagos.length > 0){
        for (let id in this.listadoPagos){
          this.totalFinalPorcentaje = convertDecimals(this.totalFinalPorcentaje) + convertDecimals(this.listadoPagos[id].porcentaje_pago)
          this.totalFinalPagos = convertDecimals(this.totalFinalPagos) + convertDecimals(this.listadoPagos[id].pago_total)
        }
      }
      this.subTotal = convertDecimals(this.subTotal)
      this.totalIva21 = convertDecimals(this.totalIva21)
      this.totalIva105 = convertDecimals(this.totalIva105)
      this.totalIva27 = convertDecimals(this.totalIva27)
      this.totalImpInterno = convertDecimals(this.totalImpInterno)
      this.totalFinalPorcentaje = convertDecimals(this.totalFinalPorcentaje)
      this.totalFinalPagos = convertDecimals(this.totalFinalPagos)
      this.totalRetEspeciales = convertDecimals(this.totalRetEspeciales)
      // calculo el total final
      this.totalFinal = convertDecimals(this.subTotal) + convertDecimals(this.totalIva21) + convertDecimals(this.totalIva105) + convertDecimals(this.totalIva27) + convertDecimals(this.totalImpInterno) + convertDecimals(this.totalRetEspeciales)
      this.totalFinal = convertDecimals(this.totalFinal)
    },
    async getRetencionesEspeciales(){
      if (this.retencionesEspeciales.length == 0){
        this.$store.dispatch('facturasCompra/get_retenciones_especiales')
          .then(response => {
            if (response.resultado == 1){
              this.retencionesEspeciales = response.retenciones
            }else{
              this.$store.dispatch('show_snackbar', {
                text: response.msj,
                color: 'error',
              })
              return
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: 'No se pudo obtener las retenciones especiales: ' + error.message,
              color: 'error',
            })
            return
          })
      }
    },
    async getFormasPagoCompra(){
      if (this.formasPago.length == 0){
        this.$store.dispatch('facturasCompra/get_fp_compras')
          .then(response => {
            if (response.resultado == 1){
              this.formasPago = response.formas_pago
            }else{
              this.$store.dispatch('show_snackbar', {
                text: response.msj,
                color: 'error',
              })
              return
            }
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: 'No se pudo obtener las formas de pago: ' + error.message,
              color: 'error',
            })
            return
          })
      }
    },
    getProveedores(){
      // control de empresa
      if (this.esNuevaFC == 1){
        if (this.selectedEmpresa == null || typeof this.selectedEmpresa != 'object' || Object.keys(this.selectedEmpresa).length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una Empresa para poder obtener los proveedores.',
            color: 'warning',
          })
          return
        }
        this.$store.state.loading = true
        this.$store.dispatch('facturasCompra/get_proveedores', {id_empresa: this.selectedEmpresa.id})
          .then(response => {
            this.$store.state.loading = false
            if (response.resultado == 1){
              this.proveedores = response.proveedores
            }else{
              this.$store.dispatch('show_snackbar', {
                text: response.msj,
                color: 'error',
              })
              return
            }
          })
          .catch(error => {
            this.$store.state.loading = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se pudo obtener los proveedores: ' + error.message,
              color: 'error',
            })
            return
          })
      }
    },
    getFCTiposComprobantes(){
      this.$store.dispatch('facturasCompra/get_fc_tipos_comprobantes')
        .then(response => {
          if (response.resultado == 1){
            this.tiposFactura = response.tiposComp
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo obtener los tipos de comprobantes: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    cargarArtsConceptos(){
      if (this.selectedTipoFact != null && this.selectedTipoFact != undefined && typeof this.selectedTipoFact == 'object' && Object.keys(this.selectedTipoFact).length > 0){
        if (Object.keys(this.selectedEmpresa).length == 0  || Object.keys(this.selectedProveedor).length == 0){
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar la Empresa y el Proveedor para poder proseguir.',
            color: 'warning',
          })
          this.selectedTipoFact = {}
          return
        }
        this.articulosParam = []
        this.conceptosParam = []
        this.articulosFactura = []
        this.conceptosFactura = []
        this.opcionBusqueda = this.selectedTipoFact.tipo_id
        this.getArtsConcepProv()
      }
    },
    getArtsConcepProv(){
      // obtener los articulos x proveedor o bien conceptos x proveedor
      this.$store.dispatch('facturasCompra/get_art_concepts_data', {id_proveedor: this.selectedProveedor.proveedor_codigo, empresa_id: this.selectedEmpresa.id, opcion: this.opcionBusqueda})
        .then(response => {
          this.loadd = false
          if (response.resultado == 1){
            // articulos o conceptos?
            if (this.opcionBusqueda == 1){
              this.articulosParam = response.itemsModal
            }else{
              this.conceptosParam = response.itemsModal
            }
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.loadd = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo obtener los articulos o los conceptos: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    completarRetEspecial(opcion){
      if (opcion == 2){
        if (this.selectedRetencion != null && typeof this.selectedRetencion == 'object' && Object.keys(this.selectedRetencion).length > 0){
          this.codigoRetEspecial = this.selectedRetencion.codigo
          this.tipoRetEspecial = this.selectedRetencion.tipo
        }
      }else{
        if (this.codigoRetEspecial.length > 0){
          this.codigoRetEspecial = this.codigoRetEspecial.toUpperCase()
          let unaRetencion = this.retencionesEspeciales.filter(element => element.codigo.toUpperCase() == this.codigoRetEspecial)
          if (unaRetencion.length > 0){
            this.selectedRetencion = unaRetencion[0]
            this.tipoRetEspecial = this.selectedRetencion.tipo
          }else{
            this.$store.dispatch('show_snackbar', {
              text: 'No se reconoce el código de retención ingresado.',
              color: 'info',
            })
            this.codigoRetEspecial = ''
            this.selectedRetencion = {}
            this.tipoRetEspecial = ''
            this.importeRetEspecial = ''
          }
        }
      }
    },
    addRetencion(){
      // controlar los campos vacios
      if (this.codigoRetEspecial.length == 0 || Object.keys(this.selectedRetencion).length == 0 || this.tipoRetEspecial.length == 0 || this.importeRetEspecial.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe completar todos los campos de la retención para agregar.',
          color: 'error',
        })
        return
      }
      // control de repetidos
      this.codigoRetEspecial = this.codigoRetEspecial.toUpperCase()
      let unControlRep = this.listadoRetenciones.filter(element => element.retencion_id == this.selectedRetencion.id)
      if (unControlRep.length > 0){
        this.$store.dispatch('show_snackbar', {
          text: 'La retención a agregar ya existe en la tabla. Revisar.',
          color: 'info',
        })
        this.codigoRetEspecial = ''
        this.selectedRetencion = {}
        this.tipoRetEspecial = ''
        this.importeRetEspecial = ''
        return
      }
      let unaRetencion = {
        retencion_nombre: this.selectedRetencion.descripcion,
        retencion_codigo: this.codigoRetEspecial,
        retencion_tipo: this.selectedRetencion.tipo,
        retencion_total: parseFloat(this.importeRetEspecial).toFixed(2),
        id: null,
        retencion_id: this.selectedRetencion.id
      }
      this.listadoRetenciones.push(unaRetencion)
      this.codigoRetEspecial = ''
      this.selectedRetencion = {}
      this.tipoRetEspecial = ''
      this.importeRetEspecial = ''
    },
    quitarRetencion(item){
      let pos = this.listadoRetenciones.indexOf(item)
      this.listadoRetenciones.splice(pos, 1)
    },
    async validaFechaPago(){
      if (this.fechaPago == '' || this.fechaPago == null || this.fechaPago == undefined){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar una fecha válida para poder proseguir.',
          color: 'error',
        })
        this.fechaPago = moment(new Date()).format('DD/MM/YYYY')
        return
      }
      // agregado el 03-05-2023 por MMURILLO, valido que la fecha que me estan pasando sea hábil
      this.$store.state.loading = true
      let diaPeticion = await this.$store.dispatch('facturasCompra/validarFecha', {fecha: this.fechaPago})
      this.$store.state.loading = false
      if (diaPeticion.resultado == 1){
        // tengo feriados?
        if (diaPeticion.feriados.length > 0){
          // tengo feriado el día que me dieron
          this.$store.dispatch('show_snackbar', {
            text: 'No se puede seleccionar la fecha ' + this.fechaPago + ' por ser ' + diaPeticion.feriados[0].nombre_feriado,
            color: 'warning',
          })
          // seteo nuevamente la fecha
          this.fechaPago = moment(new Date()).format('DD/MM/YYYY')
          // hago focus en la fecha para que ingrese otra en caso de querer hacerlo
          this.$refs.fechaPagoEstimada.focus()
          return
        }
      }else{
        this.fechaPago = moment(new Date()).format('DD/MM/YYYY')
        this.$store.dispatch('show_snackbar', {
          text: diaPeticion.msj,
          color: 'error',
        })
      }
    },
    validaPorcentaje(){
      // es vacio el porcentaje?
      if(this.porcentajePago.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe ingresar un porcentaje válido para poder proseguir.',
          color: 'error',
        })
        this.importePago = ''
        return
      }
      this.porcentajePago = parseFloat(this.porcentajePago)
      // es negativo o mayor a 100?
      if (this.porcentajePago < 0 || this.porcentajePago > 100){
        this.$store.dispatch('show_snackbar', {
          text: 'Porcentaje no válido. Debe ser un número entre 1 y 100.',
          color: 'error',
        })
        this.porcentajePago = ''
        this.importePago = ''
        return
      }
      let porcentajeFinalAcumulado = parseFloat(this.totalFinalPorcentaje.toString().replace(',', '.'))
      let totalPorcentaje = parseFloat(this.porcentajePago) + parseFloat(porcentajeFinalAcumulado)
      // la suma del porcentaje que estoy agregando y el porcentaje total acumulado hasta el momento supera el 100%?
      if (totalPorcentaje > 100){
        this.$store.dispatch('show_snackbar', {
          text: 'Porcentaje no válido. Excede el total de la Factura.',
          color: 'error',
        })
        this.porcentajePago = ''
        this.importePago = ''
        return
      }
      let porcentaje = parseFloat(parseFloat(this.porcentajePago)/100)
      this.importePago = porcentaje * parseFloat(this.datosFactura.total)
      this.importePago = this.importePago.toFixed(2)
      // estoy agregando un pago con el que llegamos al 100%?
      if (parseInt(totalPorcentaje) == 100){
        // controlo la suma de los importes
        let sumaTotall = 0
        for (let id in this.listadoPagos){
          sumaTotall = parseFloat(sumaTotall) + parseFloat(this.listadoPagos[id].pago_total)
        }
        let sumaTotal = parseFloat(sumaTotall) + parseFloat(this.importePago)
        if ((parseFloat(sumaTotal) > parseFloat(this.datosFactura.total))){
          this.importePago = parseFloat(parseFloat(this.datosFactura.total) - parseFloat(sumaTotall)).toFixed(2)
        }
      }
    },
    validaImportePago(){
      if (this.importePago.length == 0 || parseFloat(this.importePago) < 0 || parseFloat(this.importePago) > parseFloat(this.datosFactura.total)){
        this.$store.dispatch('show_snackbar', {
          text: 'Importe no válido. Debe ingresar un importe mayor a 0 y que no sobrepase el valor Total de la Factura.',
          color: 'error',
        })
        this.porcentajePago = ''
        this.importePago = ''
        return
      }
      let porcentaje = ((parseFloat(this.importePago)*100)/parseFloat(this.datosFactura.total)).toFixed(2)
      this.porcentajePago = porcentaje
    },
    addPago(){
      // control de valores vacios
      if(this.fechaPago.length == 0 || this.porcentajePago.length == 0 || this.importePago.length == 0 || Object.keys(this.selectedFP).length == 0 || Object.keys(this.selectedTipoPago).length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'Debe completar todos los campos de pago para poder agregar.',
          color: 'warning',
        })
        return
      }
      // tenemos conceptos agregados?
      if (this.conceptosFactura.length == 0 && this.articulosFactura.length == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se cargaron conceptos en la Factura. No es posible establecer fechas de pago.',
          color: 'warning',
        })
        return
      }
      // control de importe
      if (this.importePago.length == 0 || parseFloat(this.importePago) < 0 || parseFloat(this.importePago) > parseFloat(this.datosFactura.total)){
        this.$store.dispatch('show_snackbar', {
          text: 'Importe no válido. Debe ingresar un importe mayor a 0 y que no sobrepase el valor Total de la Factura.',
          color: 'error',
        })
        this.porcentajePago = ''
        this.importePago = ''
        return
      }
      // control de la sumatoria de importes del detalle de pagos y del nuevo importe
      let sumaTotal = 0
      for (let id in this.listadoPagos){
        sumaTotal = parseFloat(sumaTotal) + parseFloat(this.listadoPagos[id].pago_total)
      }
      sumaTotal = parseFloat(sumaTotal) + parseFloat(this.importePago)
      if (parseFloat(sumaTotal) > parseFloat(this.datosFactura.total)){
        this.$store.dispatch('show_snackbar', {
          text: 'No se puede agregar ya que la suma de los importes de Pago sobrepasan al Total de la Factura.',
          color: 'error',
        })
        this.porcentajePago = ''
        this.importePago = ''
        return
      }
      let unPago = {
        fecha_pago: this.fechaPago,
        porcentaje_pago: this.porcentajePago.toString().replace('.', ','),
        forma_pago: this.selectedFP.forma_pago_nombre,
        forma_pago_id: this.selectedFP.forma_pago_id,
        pago_total: this.importePago,
        tipo_pago_id: this.selectedTipoPago.tipo_pago_id,
        tipo_pago_nombre: this.selectedTipoPago.tipo_pago_nombre,
        pago: 0,
        id: null
      }
      this.listadoPagos.push(unPago)
      this.fechaPago = moment(new Date()).format('DD/MM/YYYY')
      this.porcentajePago = ''
      this.importePago = ''
      this.selectedFP = {}
    },
    quitarPago(item){
      let pos = this.listadoPagos.indexOf(item)
      this.listadoPagos.splice(pos, 1)
    },
    calcularDiasPago(){
      let fechaFacturaCompra = moment(this.datosFactura.fecha.toString().substring(6,10) + '-' + this.datosFactura.fecha.toString().substring(3,5) + '-' + this.datosFactura.fecha.toString().substring(0,2))
      let fechaActual = new Date()
      fechaActual = fechaActual.getFullYear().toString().padStart(4, '0') + '-' + (fechaActual.getMonth()+1).toString().padStart(2, '0') + '-' + fechaActual.getDate().toString().padStart(2, '0')
      fechaActual = moment(fechaActual)
      let fechaPlazoPago = fechaActual.diff(fechaFacturaCompra, 'days')
      return fechaPlazoPago
    },
    convertDecimals(numero){
      return parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
    },
    guardarCambios(){
      // control de campos obligatorios
      let error = this.verificarObligatorios()
      if (Object.keys(error).length > 0){
        this.$store.dispatch('show_snackbar', error)
        return
      }
      // controlamos a ver si agregaron pagos, si agregaron entonces deben completar el 100% del monto total para grabar
      if (this.listadoPagos.length > 0){
        this.totalFinalPorcentaje = parseInt(this.totalFinalPorcentaje)
        // el porcentaje de pago cubre el 100% del total de la factura?
        if (this.totalFinalPorcentaje != 100){
          this.$store.dispatch('show_snackbar', {
            text: 'Se deben completar los pagos con el 100% del total de la factura. No se puede guardar los cambios.',
            color: 'warning',
          })
          return
        }
        // el total calculado de pagos es igual al total que vino en el encabezado de la factura?
        if (parseFloat(this.totalFinalPagos).toFixed(2) != parseFloat(this.datosFactura.total).toFixed(2)){
          this.$store.dispatch('show_snackbar', {
            text: 'El total calculado de pagos a realizar (' + parseFloat(this.totalFinalPagos).toFixed(2) + ') no coincide con el total de la factura (' + parseFloat(this.datosFactura.total).toFixed(2) + '). No se puede guardar los cambios.',
            color: 'warning',
          })
          return
        }
      }
      // el total final calculado coincide con el total final del encabezado? 29-08-2022 POR MMURILLO, DEJAMOS GRABAR CON UNA DIFERENCIA DE +/- 1 PESO
      let totalFinalCalculado = this.convertDecimals(this.totalFinal)
      let totalEncabezado = this.convertDecimals(this.datosFactura.total)
      if ((totalEncabezado - 1) > totalFinalCalculado || (totalEncabezado + 1) < totalFinalCalculado){
        this.$store.dispatch('show_snackbar', {
          text: 'El total calculado (' + parseFloat(this.totalFinal).toFixed(2) + ') difiere con el total de la factura (' + parseFloat(this.datosFactura.total).toFixed(2) + ') por más de $1. No se puede guardar los cambios.',
          color: 'warning',
        })
        return
      }
      /*if (parseFloat(this.totalFinal).toFixed(2) != parseFloat(this.datosFactura.total).toFixed(2)){
        this.$store.dispatch('show_snackbar', {
          text: 'El total calculado (' + parseFloat(this.totalFinal).toFixed(2) + ') no coincide con el total de la factura (' + parseFloat(this.datosFactura.total).toFixed(2) + '). No se puede guardar los cambios.',
          color: 'warning',
        })
        return
      }*/
      let provBejerman = 0
      if (Object.keys(this.selectedMultiProv).length > 0) provBejerman = this.selectedMultiProv.proveedor_codigo
      // condición de pago
      let condPago = 0
      if (Object.keys(this.selectedCondPago).length > 0) condPago = this.selectedCondPago.id
      // si pasa de aqui, paso a guardar
      let encabezado = {
        fecha: this.datosFactura.fecha,
        iva: this.totalIvaFinal.toFixed(2),
        neto: this.totalNetoFinal.toFixed(2),
        subtotal: this.subTotal.toFixed(2),
        total: convertDecimals(this.datosFactura.total), // 03-09-2024 por MMURILLO, coloco el total del encabezado this.totalFinal.toFixed(2),
        referencia: this.datosFactura.referencia,
        percepcion: this.totalRetEspeciales.toFixed(2),
        emision: this.datosFactura.emision,
        numero: this.datosFactura.numero,
        tipo_factura: this.selectedTipoFactura.tipo_letra,
        tipo_factura_codigo: this.selectedTipoFactura.comp_tipo_id,
        tipo_factura_id: this.selectedTipoFactura.tipo_id,
        usuario: this.datosFactura.usuario,
        impuesto: this.totalImpInterno.toFixed(2),
        fecha_pago: moment(new Date()).format('DD/MM/YYYY'),
        dias_pago: this.calcularDiasPago(),
        sucursal: Object.keys(this.selectedSucursal).length == 0 ? null : this.selectedSucursal.id,
        id_factura: this.datosFactura.factura_id,
        fecha_contable: this.datosFactura.fecha_contable,
        proveedor_codigo: this.selectedProveedor.proveedor_codigo,
        empresa_codigo: this.selectedEmpresa.id,
        proveedor_bejerman: provBejerman,
        condicion_pago: condPago,
        estado_control: this.selectedEstado.estado_control_id
      }
      // 18-07-2022 POR MMURILLO, recalculamos el importe total y el neto
      if (this.conceptosFactura.length > 0){
        for (let id in this.conceptosFactura){
          let precioRow = this.convertDecimals(this.conceptosFactura[id].precio_total)
          this.conceptosFactura[id].precio_total = precioRow + this.convertDecimals(this.conceptosFactura[id].iva) + this.convertDecimals(this.conceptosFactura[id].total_imp_interno)
          this.conceptosFactura[id].neto = this.conceptosFactura[id].precio_total - this.convertDecimals(this.conceptosFactura[id].iva)
        }
      }
      if (this.articulosFactura.length > 0){
        for (let id in this.articulosFactura){
          let precioRow = this.convertDecimals(this.articulosFactura[id].precio_total)
          this.articulosFactura[id].precio_total = precioRow + this.convertDecimals(this.articulosFactura[id].iva) + this.convertDecimals(this.articulosFactura[id].total_imp_interno)
          this.articulosFactura[id].neto = this.convertDecimals(this.articulosFactura[id].precio_total) - this.convertDecimals(this.articulosFactura[id].iva)
        }
      }
      this.$store.dispatch('facturasCompra/update_fc_compra', {
        encabezado: encabezado,
        detalles: this.conceptosFactura,
        detallesArt: this.articulosFactura,
        periodos_pagos: this.listadoPagos,
        retenciones_especiales: this.listadoRetenciones
      })
        .then(response => {
          if (response.resultado == 1){
            this.$store.dispatch('show_snackbar', {
              text: 'Actualización Finalizada con Éxito.',
              color: 'success',
            })
            setTimeout(() => [location.reload()], 3000)
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo actualizar la factura de compra: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    volverInicio(){
      this.$router.push({ path: "/index/facturas-compra" })
    },
    marcarComoVerificada(){
      let error = this.verificarObligatorios()
      if (Object.keys(error).length > 0){
        this.$store.dispatch('show_snackbar', error)
        return
      }
      // controlamos a ver si agregaron pagos, si agregaron entonces deben completar el 100% del monto total para grabar
      if (this.listadoPagos.length > 0){
        this.totalFinalPorcentaje = parseInt(this.totalFinalPorcentaje)
        // el porcentaje de pago cubre el 100% del total de la factura?
        if (this.totalFinalPorcentaje != 100){
          this.$store.dispatch('show_snackbar', {
            text: 'Se deben completar los pagos con el 100% del total de la factura. No se puede guardar los cambios.',
            color: 'warning',
          })
          return
        }
        // el total calculado de pagos es igual al total que vino en el encabezado de la factura?
        if (parseFloat(this.totalFinalPagos).toFixed(2) != parseFloat(this.datosFactura.total).toFixed(2)){
          this.$store.dispatch('show_snackbar', {
            text: 'El total calculado de pagos a realizar (' + parseFloat(this.totalFinalPagos).toFixed(2) + ') no coincide con el total de la factura (' + parseFloat(this.datosFactura.total).toFixed(2) + '). No se puede guardar los cambios.',
            color: 'warning',
          })
          return
        }
      }
      let totalFinalCalculado = this.convertDecimals(this.totalFinal)
      let totalEncabezado = this.convertDecimals(this.datosFactura.total)
      if ((totalEncabezado - 1) > totalFinalCalculado || (totalEncabezado + 1) < totalFinalCalculado){
        this.$store.dispatch('show_snackbar', {
          text: 'El total calculado (' + parseFloat(this.totalFinal).toFixed(2) + ') difiere con el total de la factura (' + parseFloat(this.datosFactura.total).toFixed(2) + ') por más de $1. No se puede guardar los cambios.',
          color: 'warning',
        })
        return
      }
      // el total final calculado coincide con el total final del encabezado?
      /*if (parseFloat(this.totalFinal).toFixed(2) != parseFloat(this.datosFactura.total).toFixed(2)){
        this.$store.dispatch('show_snackbar', {
          text: 'El total calculado (' + parseFloat(this.totalFinal).toFixed(2) + ') no coincide con el total de la factura (' + parseFloat(this.datosFactura.total).toFixed(2) + '). No se puede guardar los cambios.',
          color: 'warning',
        })
        return
      }*/
      // condición de pago
      let condPago = 0
      if (Object.keys(this.selectedCondPago).length > 0) condPago = this.selectedCondPago.id
      else{
        this.$store.dispatch('show_snackbar', {
          text: 'No se puede verificar la Factura sin ingresar la condición de pago.',
          color: 'warning',
        })
        return
      }
      this.$store.state.loading = true
      this.$store.dispatch('facturasCompra/set_fc_verify', {idFactura: this.idFactura, condicion_pago: condPago})
        .then(response => {
          this.$store.state.loading = false
          if (response.resultado == 1){
            this.$store.dispatch('show_snackbar', {
              text: 'Se marcó Verificada con éxito.',
              color: 'success',
            })
            setTimeout(() => [location.reload()], 3000)
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo marcar como Verificada: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    verificarObligatorios(){
      let error = {}
      if (Object.keys(this.selectedEmpresa).length == 0){
        error.text = 'Debe completar la Empresa.'
        error.color = 'warning'
        return error
      }
      if (Object.keys(this.selectedProveedor).length == 0){
        error.text = 'Debe completar el Proveedor.'
        error.color = 'warning'
        return error
      }
      if (this.selectedProveedor.multicuenta == 1 && Object.keys(this.selectedMultiProv).length == 0){
        error.text = 'Debe Completar el Proveedor de Bejerman.'
        error.color = 'warning'
        return error
      }
      if (this.datosFactura.fecha_contable == null || this.datosFactura.fecha_contable == undefined || this.datosFactura.fecha_contable.length == 0){
        error.text = 'Debe completar la Fecha Contable.'
        error.color = 'warning'
        return error
      }
      if (Object.keys(this.selectedTipoFactura).length == 0){
        error.text = 'Debe completar el Tipo de Comprobante.'
        error.color = 'warning'
        return error
      }
      if (this.datosFactura.emision.length == 0){
        error.text = 'Debe completar la Emision'
        error.color = 'warning'
        return error
      }
      if (this.datosFactura.numero.length == 0){
        error.text = 'Debe completar el Numero de Factura.'
        error.color = 'warning'
        return error
      }
      if (this.datosFactura.total.length == 0){
        error.text = 'Debe completar el Imp. Total del Encabezado de la Factura.'
        error.color = 'warning'
        return error
      }
      if (Object.keys(this.selectedTipoFact).length == 0){
        error.text = 'Debe completar el Tipo de Item de la Factura.'
        error.color = 'warning'
        return error
      }
      if (this.conceptosFactura.length == 0 && this.articulosFactura.length == 0){
        error.text = 'Se debe agregar al menos un concepto/artículo para poder guardar.'
        error.color = 'warning'
        return error
      }
      if (moment(this.datosFactura.fecha, "DD/MM/YYYY").toDate() > moment(this.datosFactura.fecha_contable, "DD/MM/YYYY").toDate()){
        error.text = 'La Fecha del Comprobante no puede ser mayor a la Fecha Contable.'
        error.color = 'warning'
        return error
      }
      // agregado el 08-05-2023 por MMURILLO, cuando editan la factura controlo que hayan ingresado el estado de control
      if (this.esNuevaFC == 0 && Object.keys(this.selectedEstado).length == 0){
        error.text = 'No se puede dejar vacio el Estado de Control.'
        error.color = 'warning'
        return error
      }
      // agregado el 12-05-2023 por MMURILLO, que ingresen la moneda
      if (Object.keys(this.selectedMoneda).length == 0){
        error.text = 'No se puede dejar vacia la Moneda.'
        error.color = 'warning'
        return error
      }
      // si la moneda cotiza, se debe ingresar el monto de cotización
      if (this.selectedMoneda.cotizacion == 1 && (this.cotizacion == null || this.cotizacion == undefined || this.cotizacion == 0 || this.cotizacion.toString().length == 0)){
        error.text = 'No se puede dejar vacia la Cotización cuando la moneda debe ser convertida.'
        error.color = 'warning'
        return error
      }
      return error
    },
    crearFC(){
      // controlamos campos obligatorios
      let error = this.verificarObligatorios()
      if (Object.keys(error).length > 0){
        this.$store.dispatch('show_snackbar', error)
        return
      }
      // controlamos a ver si agregaron pagos, si agregaron entonces deben completar el 100% del monto total para grabar
      if (this.listadoPagos.length > 0){
        this.totalFinalPorcentaje = parseInt(this.totalFinalPorcentaje)
        // el porcentaje de pago cubre el 100% del total de la factura?
        if (this.totalFinalPorcentaje != 100){
          this.$store.dispatch('show_snackbar', {
            text: 'Se deben completar los pagos con el 100% del total de la factura. No se puede guardar los cambios.',
            color: 'warning',
          })
          return
        }
        // el total calculado de pagos es igual al total que vino en el encabezado de la factura?
        if (parseFloat(this.totalFinalPagos).toFixed(2) != parseFloat(this.datosFactura.total).toFixed(2)){
          this.$store.dispatch('show_snackbar', {
            text: 'El total calculado de pagos a realizar (' + parseFloat(this.totalFinalPagos).toFixed(2) + ') no coincide con el total de la factura (' + parseFloat(this.datosFactura.total).toFixed(2) + '). No se puede guardar los cambios.',
            color: 'warning',
          })
          return
        }
      }
      // el total final calculado coincide con el total final del encabezado?
      let totalFinalCalculado = this.convertDecimals(this.totalFinal)
      let totalEncabezado = this.convertDecimals(this.datosFactura.total)
      if ((totalEncabezado - 1) > totalFinalCalculado || (totalEncabezado + 1) < totalFinalCalculado){
        this.$store.dispatch('show_snackbar', {
          text: 'El total calculado (' + parseFloat(this.totalFinal).toFixed(2) + ') difiere con el total de la factura (' + parseFloat(this.datosFactura.total).toFixed(2) + ') por más de $1. No se puede guardar los cambios.',
          color: 'warning',
        })
        return
      }
      /*if (parseFloat(this.totalFinal).toFixed(2) != parseFloat(this.datosFactura.total).toFixed(2)){
        this.$store.dispatch('show_snackbar', {
          text: 'El total calculado (' + parseFloat(this.totalFinal).toFixed(2) + ') no coincide con el total de la factura (' + parseFloat(this.datosFactura.total).toFixed(2) + '). No se puede guardar los cambios.',
          color: 'warning',
        })
        return
      }*/
      // milticuenta?
      let provBejerman = 0
      if (Object.keys(this.selectedMultiProv).length > 0) provBejerman = this.selectedMultiProv.proveedor_codigo
      // condición de pago
      let condPago = 0
      if (Object.keys(this.selectedCondPago).length > 0) condPago = this.selectedCondPago.id
      // 12-05-2023 por MMURILLO, cotizacion
      let cot = 0
      if (this.cotizacion != null && this.cotizacion != undefined && this.cotizacion != 0 && this.cotizacion.toString().length != 0) cot = this.cotizacion
      this.$store.state.loading = true
      // si pasa de aqui, paso a guardar
      let encabezado = {
        fecha: this.datosFactura.fecha,
        iva: this.totalIvaFinal.toFixed(2),
        neto: this.totalNetoFinal.toFixed(2),
        subtotal: this.subTotal.toFixed(2),
        total: convertDecimals(this.datosFactura.total), // 03-09-2024 por MMURILLO, ponemos el total del encabezado  --this.totalFinal.toFixed(2),
        referencia: this.datosFactura.referencia,
        percepcion: this.totalRetEspeciales.toFixed(2),
        emision: this.datosFactura.emision,
        numero: this.datosFactura.numero,
        tipo_factura: this.selectedTipoFactura.tipo_letra,
        tipo_factura_codigo: this.selectedTipoFactura.comp_tipo_id,
        tipo_factura_id: this.selectedTipoFactura.tipo_id,
        impuesto: this.totalImpInterno.toFixed(2),
        fecha_pago: moment(new Date()).format('DD/MM/YYYY'),
        dias_pago: this.calcularDiasPago(),
        sucursal: Object.keys(this.selectedSucursal).length == 0 ? null : this.selectedSucursal.id,
        proveedor_codigo: this.selectedProveedor.proveedor_codigo,
        empresa_codigo: this.selectedEmpresa.id,
        fecha_contable: this.datosFactura.fecha_contable,
        proveedor_bejerman: provBejerman,
        condicion_pago: condPago,
        moneda_id: this.selectedMoneda.moneda_id,
        cotizacion: cot
      }
      this.$store.dispatch('facturasCompra/create_fc_compra', {
        encabezado: encabezado,
        detalles: this.conceptosFactura,
        detallesArt: this.articulosFactura,
        periodos_pagos: this.listadoPagos,
        retenciones_especiales: this.listadoRetenciones
      })
        .then(response => {
          this.$store.state.loading = false
          if (response.resultado == 1){
            this.$store.dispatch('show_snackbar', {
              text: 'Factura de Compra creada con Éxito.',
              color: 'success',
            })
            setTimeout(() => [this.$router.push({path: '/index/facturas-compra'})], 3000)
          }else{
            this.$store.dispatch('show_snackbar', {
              text: response.msj,
              color: 'error',
            })
            return
          }
        })
        .catch(error => {
          this.$store.state.loading = false
          this.$store.dispatch('show_snackbar', {
            text: 'No se pudo guardar la factura de compra: ' + error.message,
            color: 'error',
          })
          return
        })
    },
    verifyMultiCuenta(){
      if (this.esNuevaFC == 1){
        this.multiCuentaProv = []
        this.selectedMultiProv = {}
      }
      if (this.selectedProveedor == null || this.selectedProveedor == undefined){
        this.selectedProveedor = {}
      }
      if (this.selectedProveedor.multicuenta == 1 && this.esNuevaFC == 1) this.getMultiCuentaProveedor()
    },
    async getMultiCuentaProveedor(){
      this.$store.state.loading = true
      let multiPeticion = await this.$store.dispatch('facturasCompra/get_multi_proveedor', {proveedor_codigo: this.selectedProveedor.proveedor_codigo, empresa_codigo: this.selectedEmpresa.id})
      this.$store.state.loading = false
      if (multiPeticion.resultado == 1) this.multiCuentaProv = multiPeticion.proveedores
      else{
        this.$store.dispatch('show_snackbar', {
          text: multiPeticion.msj,
          color: 'error',
        })
      }
    },
    controlEstado(){
      if (this.selectedEstado == null || this.selectedEstado == undefined){
        this.selectedEstado = {}
        return
      }
      if (this.selectedEstado.seleccionable == 0){
        this.$store.dispatch('show_snackbar', {
          text: 'No se puede seleccionar el estado ' + this.selectedEstado.estado_control_nombre + '.',
          color: 'warning',
        })
        this.selectedEstado = {}
        return
      }
    },
    verificarCotizacion(){
      if (Object.keys(this.selectedMoneda).length > 0){
        if (this.selectedMoneda.cotizacion == 0) this.cotizacion = ''
      }else this.cotizacion = ''
    }
  },
  watch: {
    selectedProveedor: function(){
      if (this.selectedProveedor == null || this.selectedProveedor == undefined){
        this.selectedProveedor = {}
      }
    },
    selectedEmpresa: function(){
      if (this.selectedEmpresa == null || this.selectedEmpresa == undefined){
        this.selectedEmpresa = {}
      }
    },
    selectedSucursal: function(){
      if (this.selectedSucursal == null || this.selectedSucursal == undefined){
        this.selectedSucursal = {}
      }
    },
    selectedTipoFact: function(){
      if (this.selectedTipoFact == null || this.selectedTipoFact == undefined){
        this.selectedTipoFact = {}
      }
      if (this.selectedTipoFact.tipo_id != 0){
        if (this.selectedTipoFact.tipo_id == 1){
          this.opcionBusqueda = 1
        }else{
          this.opcionBusqueda = 2
        }
        this.getArtsConcepProv()
      }
    },
    selectedTipoFactura: function(){
      if (this.selectedTipoFactura == null || this.selectedTipoFactura == undefined){
        this.selectedTipoFactura = {}
      }
    },
    selectedRetencion: function(){
      if (this.selectedRetencion == null || this.selectedRetencion == undefined){
        this.selectedRetencion = {}
        this.codigoRetEspecial = ''
        this.tipoRetEspecial = ''
        this.importeRetEspecial = ''
      }
    },
    selectedFP: function(){
      if (this.selectedFP == null || this.selectedFP == undefined){
        this.selectedFP = {}
      }
    },
    pagoFormaIngreso: function(){
      this.porcentajePago = ''
      this.importePago = ''
      if (this.pagoFormaIngreso == false){
        this.pagoNombreForIngreso = 'Porcentaje'
      }else{
        this.pagoNombreForIngreso = 'Importe'
      }
    },
    editar: async function(){
      if (this.editar == true){
        // obtengo la info necesaria para editar
        this.$store.state.loading = true
        await this.getRetencionesEspeciales()
        await this.getFormasPagoCompra()
        this.$store.state.loading = false
      }else{

      }
    },
    conceptosFactura: function(){
      this.recalcularTotales(0)
    },
    articulosFactura: function(){
      this.recalcularTotales(0)
    },
    listadoRetenciones: function(){
      this.recalcularTotales(0)
    },
    listadoPagos: function(){
      this.recalcularTotales(0)
    }
  },
}
</script>

<style>

</style>